import { Modal, ModalProps } from 'antd'
import { FC } from 'react'
import styled from 'styled-components'
import { ThemeColor } from '../../themes'
import { H2 } from '../Heading/Heading.style'
import Text from '../Text'

type ModalWithSizingProps = ModalProps & {
  isMaxHeight: boolean
  headerBgColor?: ThemeColor | undefined
  withTabs?: boolean
  hideHeader: boolean
  hideScrollBar: boolean
}

const ModalWithSizing: FC<ModalWithSizingProps> = (props) => {
  const { isMaxHeight: _isMaxHeight, ...rest } = props
  return <Modal {...rest} />
}

export const StyledModal = styled<typeof ModalWithSizing>(ModalWithSizing)`
  padding: 0;
  ${({ isMaxHeight }) =>
    isMaxHeight ? 'height: calc(100% - 8px); min-width: calc(100% - 8px)' : ''};

  > .ant-modal-content {
    border-radius: ${({ theme }) => theme.border.radiusLarge};
    overflow: hidden;
    ${({ isMaxHeight }) => (isMaxHeight ? 'height: 100%' : '')};

    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      // 44px is the height of the modal header
      // else defaults to 54px which is fine for modal with tabs
      ${({ withTabs, hideHeader }) =>
        !withTabs && !hideHeader ? 'height: 44px; width: 44px; line-height: 44px;' : ''}

      svg {
        ${({ theme, withTabs }) => (withTabs ? `color: ${theme.colors.typeSecondary}` : '')};
      }
    }

    > .ant-modal-header {
      border: 2px solid ${({ theme }) => theme.colors.bgSecondary};
      border-top-left-radius: ${({ theme }) => theme.border.radiusLarge};
      border-top-right-radius: ${({ theme }) => theme.border.radiusLarge};
      background: ${({ theme, headerBgColor }) =>
        headerBgColor ? theme.colors[headerBgColor] : theme.colors.black};
      ${({ withTabs, hideHeader }) => (withTabs || hideHeader ? 'display: none' : '')};
      padding: 0.75rem;

      > .ant-modal-title {
        color: ${({ theme }) => theme.colors.typeDarkPrimary};
        display: flex;
        justify-content: center;
        font-size: 0.75rem;
        line-height: 1rem;
        font-weight: 700;

        ::selection {
          color: ${({ theme }) => theme.colors.black};
          background: ${({ theme }) => theme.colors.white};
        }
      }
    }

    > .ant-modal-body {
      padding: 0;
      overflow: ${({ hideScrollBar }) => (hideScrollBar ? 'hidden' : 'auto')};
      ${({ isMaxHeight, withTabs, hideHeader }) =>
        isMaxHeight
          ? `position: absolute; top: ${withTabs || hideHeader ? '4px' : '58px'} ; bottom: 0; left: 0; right: 0;`
          : 'max-height: 90vh;'};
    }
  }
`

export const ContentContainer = styled.div<{ $greyBackground?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.architecture2};
  ${({ $greyBackground, theme }) =>
    $greyBackground
      ? `
    background-color: ${theme.colors.bgPrimary};
    border-top: 1px solid ${theme.colors.architecture3};
    border-bottom: 1px solid ${theme.colors.architecture3};
    `
      : ''};
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2.5rem;
`

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1.25rem;
  gap: 1.25rem;
`

export const HeaderText = styled(H2)<{ center?: boolean }>`
  margin-bottom: 0;
  ${({ center }) => (center ? 'display: flex; justify-content: center;' : '')};
`

export const LightweightText = styled(Text)`
  font-weight: 400;
`

export const ContentText = styled(Text)`
  font-weight: 700;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.typeSecondary};
`

export const SpacedContent = styled.div`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: 0.5rem;
  }
`
